import React from 'react';
import { bool } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortNumericUpAlt, faUserPlus, faShareAlt, faFluxCapacitor,
} from '@fortawesome/pro-light-svg-icons';

import {
  List, ListItem, ListItemText, ListItemAvatar, Typography,
} from '@material-ui/core';
import useStyles from './styles';

const GoldBenefits = ({ highlights = false, extraFeatures = false }) => {
  const styles = useStyles();

  return (
        <>
          {!highlights && <Typography variant="h5">
              Gold Benefits
          </Typography>
          }
          <List className={styles.nomargin}>
            <ListItem>
              <ListItemAvatar>
                <FontAwesomeIcon icon={faSortNumericUpAlt} className={styles.goldAvatar}/>
              </ListItemAvatar>
              <ListItemText
                primary="Up to 6 loyalty cards"
                secondary="Build and share up to 6 digital loyalty cards with your customers for Apple Wallet and Google Pay" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <FontAwesomeIcon icon={faUserPlus} className={styles.goldAvatar}/>
              </ListItemAvatar>
              <ListItemText
              primary="10,000 active customers"
              secondary={`We appreciate you may have different customers at different
              times. We will do our best to ensure only the customers using your Loyalty Card are counted.`}
              />
            </ListItem>
            {extraFeatures && (
              <>
              <ListItem>
                <ListItemAvatar>
                  <FontAwesomeIcon icon={faShareAlt} className={styles.goldAvatar}/>
                </ListItemAvatar>
                <ListItemText
                primary="Share and Scan"
                secondary={`Your dashboard area with access to sharing and scanning your loyalty card
                  to manage the loyalty of your customers.`}
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <FontAwesomeIcon icon={faFluxCapacitor} className={styles.goldAvatar}/>
                </ListItemAvatar>
                <ListItemText
                  primary="Access to future features"
                  secondary={`Our dev team is always building new features, we want to make this the best
                    digital loyalty platform on the market. All new features will be made available to all current customers.`}
                  />
              </ListItem>
              </>
            )}
          </List>
          </>
  );
};
GoldBenefits.propTypes = { highlights: bool, extraFeatures: bool };

export default GoldBenefits;
